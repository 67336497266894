import React, { Fragment, useState, useEffect } from "react";
import "./Home.css";
import Header from "../components/Header";
import { motion } from "framer-motion";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Rocket from "../Assets/home/rocket2.png";
import HomeBg from "../Assets/home/HomeBg-dark.jpg";
import HomeBg2 from "../Assets/home/HomeBg-light.jpg";
import { toast } from "react-toastify";
import FormVector from "../Assets/home/form.png";
import axios from "axios";
import { IoMdArrowDropright } from "react-icons/io";

const headingVariants = {
  visible: {
    transition: {
      staggerChildren: 0.07,
    },
  },
};
const headingChildVariants = {
  hidden: {
    scale: 0.7,
    y: -5,
    x: -5,
    color: "#a020f0",
  },
  visible: {
    scale: 1,
    x: 0,
    y: 0,
    color: "#d9e4f5",
    transition: {
      type: "spring",
      stiffness: 500,
      ease: "easeInOut",
    },
  },
};

export default function Home() {
  const homeHeading = "Leasing";
  const [homebgChange, setHomebgChange] = useState(true);
  const [data, setData] = useState(null);
  return (
    <Fragment>
      <img src={homebgChange ? HomeBg : HomeBg2} className="home-bg" />
      <Header homebgChange={homebgChange} setHomebgChange={setHomebgChange} />
      <main className="home">
        <div className="home-body">
          <motion.div>
            <img src={Rocket} className="home-rocket" />
          </motion.div>
          <motion.div
            className="d-flex gap-2"
            variants={headingVariants}
            initial="hidden"
            animate="visible"
          >
            {homeHeading.split("").map((text, index) => {
              return text === " " ? (
                <h1 key={index}>&nbsp;&nbsp;</h1>
              ) : (
                <motion.h1
                  className="home-card-body-h1 "
                  key={index}
                  variants={headingChildVariants}
                >
                  {text}
                </motion.h1>
              );
            })}
          </motion.div>
        </div>
      </main>
      <Form />
    </Fragment>
  );
}
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const formVariants = {
  hidden: {
    scaleY: 0,
  },
  visible: {
    scaleY: 1,
    transition: {
      type: "spring",
      stiffness: 120,
    },
  },
};
const formChildVariants = {
  hidden: {
    scaleX: 0.5,
  },
  visible: {
    scaleX: 1,
    transition: {
      type: "spring",
      stiffness: 120,
    },
  },
};

function Form() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);
  const [value, setValue] = useState();
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [businesses, setBusinesses] = useState([]);
  const [unit, setUnit] = useState([]);
  const [prospectData, setProspectData] = useState(null);
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const response = await axios.get("https://app-qdk5soz5sq-uc.a.run.app/api/data/all");

        // Set the data for both SuperProperties and Businesses
        setData(response.data.superProperties.value);
        setBusinesses(response.data.businesses.value);
        setUnit(response.data.unitsOfMeasure.value);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchAllData();
  }, []);
  const [inputValue, setInputValue] = useState({
    Name: "",
    LineOfBusiness: "1000",
    Telephone: "",
    FaxNo: "",
    Email: "",
    InterestedIn: "FL",
    PropertyType: "Retail",
    BrandName: "",
    Unit: "SQ.M",
    RequiredArea: "",
    Notes: "",
  });
  function fileInputHandler(e) {
    setFile(e.target.files[0]);

    toast.success(`Uploaded successfully!`, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  function inputValueHandler(e) {
    const name = e.target.name;
    const value = e.target.value;
    setInputValue({ ...inputValue, [name]: value });
  }
  function showDropdownHandler() {
    setShowDropdown(!showDropdown);
    setShowDropdown2(false);
  }
  function showDropdownHandler2() {
    setShowDropdown2(!showDropdown2);
    setShowDropdown(false);
  }
  function showDropdownRemoveHandler() {
    setShowDropdown(false);
    setShowDropdown2(false);
  }
  function addValueToInput1(business) {
    setInputValue({ ...inputValue, LineOfBusiness: business });
    setShowDropdown(false);
  }
  function addValueToInput2(unit) {
    setInputValue({ ...inputValue, Unit: unit });
    setShowDropdown2(false);
  }
  useEffect(() => {
    if (prospectData) {
      const sendProspectData = async () => {
        try {
          const response = await axios.post(
            "https://app-qdk5soz5sq-uc.a.run.app/api/create-prospects",
            prospectData
          );
          toast.success("Sent Successfully!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        } catch (error) {
          console.error("Error creating prospects:", error);
        } finally {
          setProspectData(null);
        }
      };
      sendProspectData();
    }
  }, [prospectData]);

  async function formSubmitHandler(e) {
    e.preventDefault();
    if (
      !inputValue.Name ||
      !inputValue.LineOfBusiness ||
      !inputValue.Telephone ||
      !inputValue.FaxNo ||
      !inputValue.Email ||
      !inputValue.Unit ||
      !inputValue.InterestedIn ||
      !inputValue.PropertyType ||
      !inputValue.RequiredArea ||
      !inputValue.Notes ||
      !inputValue.BrandName
    ) {
      toast.warn("Please Enter Your Details!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else if (!value) {
      toast.warn("Please Enter Phone Number!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else if (!file) {
      toast.warn("Please Upload Your File!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {
      setProspectData({
        name: inputValue.Name,
        superproperty: inputValue.InterestedIn,
        brandName: inputValue.BrandName,
        LineofBusiness: inputValue.LineOfBusiness,
        area: inputValue.RequiredArea,
        Unit: inputValue.Unit,
        propertid: inputValue.PropertyType,
        notes: inputValue.Notes,
        Phone: value,
        email: inputValue.Email,
        Fax: inputValue.FaxNo,
        Company: "USMF",
        country: "USA",
      });
      e.target.reset();
      setValue();
      setFile(null);
      setInputValue({
        Name: "",
        LineOfBusiness: "",
        Telephone: "",
        FaxNo: "",
        Email: "",
        InterestedIn: "",
        PropertyType: "",
        BrandName: "",
        Unit: "",
        RequiredArea: "",
        Notes: "",
      });
    }
  }
  return (
    <section className="home-form" id="Leasing">
      <div className="form-heading">
        <h1>Are you interested in renting a space?</h1>
        <img src={FormVector} alt="form" className="form-vector" />
      </div>
      <div className="container">
        <motion.form
          variants={formVariants}
          initial="hidden"
          whileInView="visible"
          onSubmit={formSubmitHandler}
        >
          <div className="form-name">
            <label htmlFor="name" className="form-label">
              Name
            </label>
            <motion.input
              type="text"
              name="Name"
              value={inputValue.Name}
              className="form-input form-control"
              id="name"
              placeholder=""
              onChange={inputValueHandler}
              onClick={showDropdownRemoveHandler}
              variants={formChildVariants}
            />
          </div>
          <div className="form-line-of-Business">
            <label htmlFor="lineOfBusiness" className="form-label">
              Line of Business
            </label>
            <div className="input-group">
              <motion.input
                type="text"
                name="LineOfBusiness"
                value={inputValue.LineOfBusiness}
                className="form-input form-control"
                id="lineOfBusiness"
                placeholder=""
                onChange={inputValueHandler}
                onClick={showDropdownHandler}
                variants={formChildVariants}
                aria-label="LineOfBusiness"
                aria-describedby="LineOfBusiness-span"
              />
              <span className="input-group-text" id="LineOfBusiness-span">
                <IoMdArrowDropright className="line-of-business-arrow" />
              </span>
            </div>
            {showDropdown && (
              <div className="drop-down">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Business</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {businesses &&
                      businesses
                        .filter((business) => {
                          const caseSensitive = true;

                          const inputValueTrimmed =
                            inputValue.LineOfBusiness.trim();

                          if (inputValueTrimmed === "") {
                            return business;
                          }

                          if (caseSensitive) {
                            const upperInput = inputValueTrimmed.toUpperCase();
                            return (
                              business.Description.toUpperCase().includes(
                                upperInput
                              ) ||
                              business.LineOfBusiness.toUpperCase().includes(
                                upperInput
                              )
                            );
                          } else {
                            const lowerInput = inputValueTrimmed.toLowerCase();
                            return (
                              business.Description.toLowerCase().includes(
                                lowerInput
                              ) ||
                              business.LineOfBusiness.toLowerCase().includes(
                                lowerInput
                              )
                            );
                          }
                        })
                        .map((business, index) => {
                          return (
                            <tr
                              onClick={() =>
                                addValueToInput1(business.LineOfBusiness)
                              }
                              key={business["@odata.etag"] || index}
                            >
                              <td>{business.LineOfBusiness}</td>
                              <td>{business.Description}</td>
                            </tr>
                          );
                        })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className="form-line-of-Business">
            <label htmlFor="telephone" className="form-label">
              Telephone
            </label>
            <motion.input
              type="number"
              name="Telephone"
              value={inputValue.Telephone}
              className="form-input form-control"
              id="telephone"
              placeholder=""
              onChange={inputValueHandler}
              onClick={showDropdownRemoveHandler}
              variants={formChildVariants}
            />
          </div>
          <div className="form-line-of-Business">
            <label htmlFor="faxNo" className="form-label">
              Fax No.
            </label>
            <motion.input
              type="number"
              name="FaxNo"
              value={inputValue.FaxNo}
              className="form-input form-control"
              id="faxNo"
              placeholder=""
              onChange={inputValueHandler}
              onClick={showDropdownRemoveHandler}
              variants={formChildVariants}
            />
          </div>
          <div className="form-phone-number">
            <label htmlFor="phoneNumber" className="form-label">
              Phone Number
            </label>
            <motion.div
              className="form-input-phone-div"
              variants={formChildVariants}
            >
              <PhoneInput
                className="form-input-phone"
                value={value}
                onChange={setValue}
                onClick={showDropdownRemoveHandler}
              />
            </motion.div>
          </div>
          <div className="form-gmail">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <motion.input
              type="email"
              name="Email"
              value={inputValue.Email}
              className="form-input form-control"
              id="email"
              placeholder=""
              onChange={inputValueHandler}
              onClick={showDropdownRemoveHandler}
              variants={formChildVariants}
            />
          </div>
          <div className="form-selected">
            <label htmlFor="interestedIn" className="form-label">
              Interested In
            </label>
            <motion.select
              className="form-select form-input"
              name="InterestedIn"
              id="interestedIn"
              value={inputValue.InterestedIn}
              aria-label="Default select example"
              variants={formChildVariants}
              onChange={inputValueHandler}
              onClick={showDropdownRemoveHandler}
            >
              {data &&
                data.map((dat, index) => {
                  return (
                    <option
                      value={dat.SuperPropertyId}
                      key={dat["@odata.etag"] || index}
                    >
                      {dat.SuperPropertyId}
                    </option>
                  );
                })}
            </motion.select>
          </div>
          <div className="form-prperty-type">
            <label htmlFor="propertyType" className="form-label">
              Property type
            </label>
            <motion.select
              type="text"
              name="PropertyType"
              value={inputValue.PropertyType}
              className="form-input form-select"
              id="propertyType"
              placeholder=""
              onChange={inputValueHandler}
              onClick={showDropdownRemoveHandler}
              variants={formChildVariants}
            >
              <option value="Retail">Retail</option>
              <option value="Wholesale">Wholesale</option>
              <option value="Warehouse">Warehouse</option>
              <option value="Other">Other</option>
            </motion.select>
          </div>
          <div className="form-name">
            <label htmlFor="brand" className="form-label">
              Brand Name
            </label>
            <motion.input
              type="text"
              name="BrandName"
              value={inputValue.BrandName}
              className="form-input form-control"
              id="brand"
              placeholder=""
              onChange={inputValueHandler}
              onClick={showDropdownRemoveHandler}
              variants={formChildVariants}
            />
          </div>
          <div className="form-Unit">
            <label htmlFor="Unit" className="form-label">
              Unit
            </label>
            <div className="input-group">
              <motion.input
                type="text"
                name="Unit"
                value={inputValue.Unit}
                className="form-input form-control"
                id="Unit"
                placeholder=""
                onChange={inputValueHandler}
                onClick={showDropdownHandler2}
                variants={formChildVariants}
                aria-label="Unit"
                aria-describedby="Unit-span"
              />
              <span className="input-group-text" id="Unit-span">
                <IoMdArrowDropright className="Unit-arrow" />
              </span>
            </div>

            {showDropdown2 && (
              <div className="drop-down">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Unit</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {unit &&
                      unit
                        .filter((uni) => {
                          const caseSensitive = true;

                          const inputValueTrimmed = inputValue.Unit.trim();

                          if (inputValueTrimmed === "") {
                            return uni;
                          }

                          if (caseSensitive) {
                            const upperInput = inputValueTrimmed.toUpperCase();
                            return (
                              uni.UnitSymbol.toUpperCase().includes(
                                upperInput
                              ) ||
                              uni.UnitDescription.toUpperCase().includes(
                                upperInput
                              )
                            );
                          } else {
                            const lowerInput = inputValueTrimmed.toLowerCase();
                            return (
                              uni.UnitSymbol.toLowerCase().includes(
                                lowerInput
                              ) ||
                              uni.UnitDescription.toLowerCase().includes(
                                lowerInput
                              )
                            );
                          }
                        })
                        .map((uni, index) => {
                          return (
                            <tr
                              onClick={() => addValueToInput2(uni.UnitSymbol)}
                              key={uni["@odata.etag"] || index}
                            >
                              <td>{uni.UnitSymbol}</td>
                              <td>{uni.UnitDescription}</td>
                            </tr>
                          );
                        })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className="form-required-area">
            <label htmlFor="RequiredArea" className="form-label">
              Required Area
            </label>
            <motion.input
              type="number"
              name="RequiredArea"
              value={inputValue.RequiredArea}
              className="form-input form-control"
              id="RequiredArea"
              placeholder=""
              onChange={inputValueHandler}
              onClick={showDropdownRemoveHandler}
              variants={formChildVariants}
            />
          </div>
          <div className="form-floating form-notes">
            <motion.textarea
              className="form-input form-control"
              name="Notes"
              placeholder="Leave a comment here"
              id="Notes"
              value={inputValue.Notes}
              onChange={inputValueHandler}
              onClick={showDropdownRemoveHandler}
              variants={formChildVariants}
            ></motion.textarea>
            <label htmlFor="Notes">Notes</label>
          </div>
          <motion.div
            className="form-button-group"
            variants={formChildVariants}
          >
            <div>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                sx={{
                  backgroundColor: "#a020f0",
                  boxShadow:
                    "rgba(0, 0, 0, 0.3) 0px 19px 38px,rgba(0, 0, 0, 0.22) 0px 15px 12px",
                }}
                size="large"
                className="form-upload-button"
              >
                Upload files
                <VisuallyHiddenInput
                  type="file"
                  onChange={fileInputHandler}
                  multiple
                />
              </Button>
              {file && <p className="text-center">{file.name}</p>}
            </div>
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#a020f0",
                boxShadow:
                  "rgba(0, 0, 0, 0.3) 0px 19px 38px,rgba(0, 0, 0, 0.22) 0px 15px 12px",
              }}
              size="large"
              className="form-submit-button"
            >
              Send
            </Button>
          </motion.div>
        </motion.form>
      </div>
    </section>
  );
}
