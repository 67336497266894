import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import ScrollTop from "./components/ScrollTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
      <ScrollTop />
      <ToastContainer />
    </div>
  );
}

export default App;
